import "core-js/modules/es.array.push.js";
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-742f9ab7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\n\t\t\t\toverflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = ["href"];
const _hoisted_7 = {
  key: 2
};
const _hoisted_8 = {
  key: 3
};
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = ["href"];
const _hoisted_11 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_12 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_13 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_14 = ["innerHTML"];
const _hoisted_15 = {
  key: 4,
  style: {
    "text-align": "center"
  }
};
import { ref, reactive, onMounted } from 'vue';
import { myMixin } from '../mixin.js';
export default {
  __name: 'doc_tool',
  setup(__props) {
    const {
      readExcelFromRemoteFile,
      fetchTextFile,
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let showFile = ref(false);
    let currentRow = reactive({
      obj: {}
    });
    let srcList = reactive({
      obj: []
    });
    let currentFile = reactive({
      obj: {}
    });
    let fileList = reactive({
      obj: []
    });
    let reqData = reactive({
      obj: {
        url: '/docTool/getDocumentForAdmin',
        data: {
          name: '',
          agent_id: ''
        }
      }
    });
    let access_file = reactive({
      obj: {}
    });
    let vHtml = ref();
    let currentTxt = ref();
    let columns = reactive([{
      name: 'doc_title',
      title: '文档标题',
      dataIndex: 'doc_title',
      width: 150
    }, {
      name: 'doc_type',
      title: '文档类型 ',
      dataIndex: 'doc_type',
      width: 100
    }, {
      name: 'file_name',
      title: '文件名称 ',
      dataIndex: 'file_name',
      width: 180
    }, {
      name: 'doc_content',
      title: '内容',
      dataIndex: 'doc_content'
    }, {
      name: 'create_dtme',
      title: '上传时间',
      dataIndex: 'create_dtme',
      width: 150
    }, {
      name: 'access_path',
      title: '文件',
      dataIndex: 'access_path',
      width: 150
    }]);
    let columns1 = reactive([{
      name: 'doc_title',
      title: '文档标题'
    }, {
      name: 'doc_type',
      title: '文档类型 ',
      type: 'radio',
      options: ['文档', '图片', '压缩包', '其他']
    }, {
      name: 'doc_file',
      type: 'file'
    }, {
      name: 'doc_content',
      title: '内容',
      type: 'text'
    }]);
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let imgList = reactive({
      obj: []
    });
    let users = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      reqData.obj.data.agent_id = account._id;
      currentRow.obj.agent_id = account._id;
      doGetData();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      smsWayShow.value = true;
    }
    async function doGetData() {
      if (account.account_auth !== 'admin') {
        reqData.obj.url = '/docTool/getDocument';
      }
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, '/docTool/deleteDocument/' + id));
      doGetData();
    }
    async function handelDocument(row) {
      let data = {};
      let close_status = row.close_status == 1 ? 0 : 1;
      const res = await withLoading(apiAll(data, '/docTool/handelDocument/' + row._id + '/' + close_status));
      doGetData();
    }
    async function addSmsWay() {
      await uploadFile();
      const res = await withLoading(apiAll(currentRow.obj, '/docTool/addDocument'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    async function uploadFile() {
      let formData = new FormData();
      if (fileList.obj && fileList.obj.length > 0) {
        formData.append('files', fileList.obj[0].raw);
        const res = await withLoading(formDataApi(formData, '/doc/uploadFile'));
        if (res.code && res.code < 0) {
          errorMsgBox(res.message);
        } else {
          access_file.obj = res[0];
          currentRow.obj.access_file = res[0];
        }
      }
    }
    async function preview(row) {
      currentFile.obj = row;
      currentFile.obj.expandName = currentFile.obj.file_name.split('.').pop();
      if (currentFile.obj.expandName == 'png' || currentFile.obj.expandName == 'jpg' || currentFile.obj.expandName == 'jpeg' || currentFile.obj.expandName == 'svg' || currentFile.obj.expandName == 'webp') {
        srcList.obj = [];
        srcList.obj.push(currentFile.obj.access_path);
        nextTick(() => {
          document.querySelector('#show-image').click();
          //获取到的是数据更新后的Dom数据
        });
      } else {
        if (currentFile.obj.expandName == 'txt') {
          currentTxt.value = await fetchTextFile(currentFile.obj.access_path);
        } else if (currentFile.obj.expandName == 'doc') {
          await readExcelFromRemoteFile(currentFile.obj.access_path, value => {
            vHtml.value = value;
          });
        }
        showFile.value = true;
      }
    }
    // // 在线查看word文件
    // function readExcelFromRemoteFile(url) {
    //   var xhr = new XMLHttpRequest();
    //   xhr.open("get", url, true);
    //   xhr.responseType = "arraybuffer";
    //   xhr.onload = function () {
    //     if (xhr.status == 200) {
    //       mammoth
    //         .convertToHtml({ arrayBuffer: new Uint8Array(xhr.response) })
    //         .then(function (resultObject) {
    //           nextTick(() => {
    //             vHtml.value = resultObject.value;
    //           });
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   };
    //   xhr.send();
    // }

    async function exportUser() {
      let data = {};
      const res = await withLoading(apiAll(data, '/docExcel/addPostExcel/'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.code);
      } else {
        successMsgBox('导出成功');
        // let access_path = res.access_path;
        window.open(res.access_path);
      }
    }
    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_DownloadOutlined = _resolveComponent("DownloadOutlined");
      const _component_a_button = _resolveComponent("a-button");
      const _component_EyeOutlined = _resolveComponent("EyeOutlined");
      const _component_a_space = _resolveComponent("a-space");
      const _component_a_table = _resolveComponent("a-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_Plus = _resolveComponent("Plus");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_VueOfficeDocx = _resolveComponent("VueOfficeDocx");
      const _component_VueOfficeExcel = _resolveComponent("VueOfficeExcel");
      const _component_VueOfficePdf = _resolveComponent("VueOfficePdf");
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left"
          }
        }, {
          default: _withCtx(() => [_unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 新增公共模块 ")]),
            _: 1
          }, 8, ["size"])) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(account).account_auth == 'admin' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: true,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "180px",
            "column-key": index,
            width: i.width ? i.width : 'auto',
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [i.name == 'month' ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row[i.name]), 1)) : i.name == 'access_path' && scope.row.access_path ? (_openBlock(), _createElementBlock("span", _hoisted_5, [_createVNode(_component_el_button, {
              text: "",
              link: "",
              icon: "download"
            }, {
              default: _withCtx(() => [_createElementVNode("a", {
                href: scope.row.access_path
              }, "下载", 8, _hoisted_6)]),
              _: 2
            }, 1024), _createVNode(_component_el_button, {
              style: {
                "margin-left": "10px"
              },
              text: "",
              link: "",
              icon: "view",
              onClick: $event => preview(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode("预览")]),
              _: 2
            }, 1032, ["onClick"])])) : i.name == 'doc_content' && scope.row[i.name] ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row[i.name].split(';'), (content, index) => {
              return _openBlock(), _createElementBlock("p", null, _toDisplayString(content), 1);
            }), 256))])) : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(scope.row[i.name]), 1))]),
            _: 2
          }, 1032, ["look", "column-key", "width", "label"]);
        }), 128)), _unref(account).account_auth == 'admin' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          width: "200",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => handelDocument(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.close_status == 1 ? '关闭' : '开启'), 1)]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_a_table, {
        dataSource: _unref(dataList).obj,
        columns: _unref(columns)
      }, {
        bodyCell: _withCtx(({
          column,
          record
        }) => [column.name == 'access_path' ? (_openBlock(), _createBlock(_component_a_space, {
          key: 0
        }, {
          default: _withCtx(() => [_createVNode(_component_a_button, null, {
            icon: _withCtx(() => [_createVNode(_component_DownloadOutlined)]),
            default: _withCtx(() => [_createElementVNode("a", {
              href: record.access_path
            }, "下载", 8, _hoisted_10)]),
            _: 2
          }, 1024), _createVNode(_component_a_button, {
            onClick: $event => preview(record)
          }, {
            icon: _withCtx(() => [_createVNode(_component_EyeOutlined)]),
            default: _withCtx(() => [_createTextVNode(" 预览 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 2
        }, 1024)) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["dataSource", "columns"])]))]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[1] || (_cache[1] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[2] || (_cache[2] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: exportUser
        }, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }, 8, ["size"])])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "登记&修改信息",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'password' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                type: "password",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 2,
                type: "textarea",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'sex' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 3,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio, {
                  label: "男",
                  value: "男"
                }), _createVNode(_component_el_radio, {
                  label: "女",
                  value: "女"
                })]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])) : i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 4,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.options, (j, index) => {
                  return _openBlock(), _createBlock(_component_el_radio, {
                    label: j,
                    value: j,
                    key: index
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : i.type == 'file' ? (_openBlock(), _createBlock(_component_el_upload, {
                key: 5,
                style: {
                  "display": "inline-block"
                },
                action: "#",
                limit: "1",
                "auto-upload": false,
                "on-change": _ctx.handleChange,
                "file-list": _unref(fileList).obj,
                "onUpdate:fileList": _cache[6] || (_cache[6] = $event => _unref(fileList).obj = $event)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon, {
                  class: "avatar-uploader-icon"
                }, {
                  default: _withCtx(() => [_createVNode(_component_Plus)]),
                  _: 1
                })]),
                _: 1
              }, 8, ["on-change", "file-list"])) : i.type == 'text' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 6,
                rows: "5",
                type: "textarea",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 7,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(showFile),
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(showFile) ? showFile.value = $event : showFile = $event),
        fullscreen: true,
        width: _unref(getDialogWdith),
        center: ""
      }, {
        default: _withCtx(() => [_unref(currentFile).obj.expandName == 'docx' ? (_openBlock(), _createBlock(_component_VueOfficeDocx, {
          key: 0,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'doc' ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "wordView",
          innerHTML: _unref(vHtml)
        }, null, 8, _hoisted_14)) : _unref(currentFile).obj.expandName == 'xlx' || _unref(currentFile).obj.expandName == 'xlsx' ? (_openBlock(), _createBlock(_component_VueOfficeExcel, {
          key: 2,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : _unref(currentFile).obj.expandName == 'pdf' ? (_openBlock(), _createBlock(_component_VueOfficePdf, {
          key: 3,
          src: _unref(currentFile).obj.access_path
        }, null, 8, ["src"])) : (_openBlock(), _createElementBlock("div", _hoisted_15, [_withDirectives(_createElementVNode("textarea", {
          style: {
            "border": "none"
          },
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(currentTxt) ? currentTxt.value = $event : currentTxt = $event),
          readonly: "",
          rows: "20",
          cols: "80"
        }, null, 512), [[_vModelText, _unref(currentTxt)]])]))]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_image, {
        id: "show-image",
        style: {
          "width": "0",
          "height": "0"
        },
        src: _unref(currentFile).obj.url,
        "zoom-rate": 1.2,
        "max-scale": 7,
        "min-scale": 0.2,
        "preview-src-list": _unref(srcList).obj,
        "initial-index": _unref(srcList).obj.length - 1,
        fit: "cover"
      }, null, 8, ["src", "preview-src-list", "initial-index"])])]);
    };
  }
};