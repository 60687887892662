import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6bdeeca8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\n\t\t\t\toverflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = {
  key: 2
};
const _hoisted_5 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
import { ref, reactive, onMounted } from 'vue';
import { myMixin } from '../../mixin.js';
export default {
  __name: 'kousuan_exercises',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let currentRow = reactive({
      obj: {}
    });
    let type = 'exercises';
    let reqData = reactive({
      obj: {
        url: '/getData/' + type,
        data: {
          name: '',
          agent_id: ''
        }
      }
    });
    let columns = reactive([{
      name: 'grade',
      title: '年级'
    }, {
      name: 'exercises_type',
      title: '题目类型'
    }, {
      name: 'num1_length',
      title: '第一个参数长度'
    }, {
      name: 'num2_length',
      title: '第二个参数长度'
    }, {
      name: 'calculate_symbol',
      title: '计算符号(加减乘除)'
    }]);
    let columns1 = reactive([{
      name: 'grade_id',
      title: '年级',
      type: 'grade'
    }, {
      name: 'exercises_type',
      title: '题目类型'
    }, {
      name: 'num1_length',
      type: 'number',
      title: '第一个参数长度'
    }, {
      name: 'num2_length',
      type: 'number',
      title: '第二个参数长度'
    }, {
      name: 'calculate_symbol',
      type: 'calculate_symbol',
      title: '计算符号(加减乘除)'
    }]);
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let imgList = reactive({
      obj: []
    });
    let users = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let gradeList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      reqData.obj.data.agent_id = account._id;
      currentRow.obj.agent_id = account._id;
      doGetData();
      getAllGrade();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      smsWayShow.value = true;
    }
    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, '/deleteData/' + type + '/' + id));
      doGetData();
    }
    async function getAllGrade() {
      const res = await withLoading(apiAll(currentRow.obj, '/getAllData/grade'));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        gradeList.obj = res.result;
      }
    }
    async function addSmsWay() {
      const res = await withLoading(apiAll(currentRow.obj, '/saveData/' + type));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 10 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event),
            clearable: "",
            onKeyup: _cache[1] || (_cache[1] = _withKeys($event => doGetData(), ["enter"])),
            onClear: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }, 8, ["size"]), _createVNode(_component_el_button, {
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[4] || (_cache[4] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode("新增练习题类别")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "180px",
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [i.name == 'grade' ? (_openBlock(), _createBlock(_component_el_tag, {
              key: 0,
              type: _ctx.success
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row[i.name] ? scope.row[i.name].name : ''), 1)]),
              _: 2
            }, 1032, ["type"])) : i.name == 'calculate_symbol' ? (_openBlock(), _createBlock(_component_el_tag, {
              key: 1,
              type: scope.row[i.name] == 1 ? 'primary' : scope.row[i.name] == 2 ? 'info' : scope.row[i.name] == 3 ? 'success' : scope.row[i.name] == 4 ? 'wain' : ''
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row[i.name] == 1 ? '加法' : scope.row[i.name] == 2 ? '减法' : scope.row[i.name] == 3 ? '乘法' : scope.row[i.name] == 4 ? '除法' : ''), 1)]),
              _: 2
            }, 1032, ["type"])) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row[i.name]), 1))]),
            _: 2
          }, 1032, ["look", "column-key", "label"]);
        }), 128)), _createVNode(_component_el_table_column, {
          width: "350",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[5] || (_cache[5] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[6] || (_cache[6] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "登记&修改题型",
        right: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "170px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'grade' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 1,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gradeList).obj, (k, index1) => {
                  return _openBlock(), _createBlock(_component_el_radio_button, {
                    label: k._id
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(k.name), 1)]),
                    _: 2
                  }, 1032, ["label"]);
                }), 256))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : i.type == 'calculate_symbol' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 2,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event,
                size: _ctx.size
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio_button, {
                  label: 1
                }, {
                  default: _withCtx(() => [_createTextVNode("加")]),
                  _: 1
                }), _createVNode(_component_el_radio_button, {
                  label: 2
                }, {
                  default: _withCtx(() => [_createTextVNode("减")]),
                  _: 1
                }), _createVNode(_component_el_radio_button, {
                  label: 3
                }, {
                  default: _withCtx(() => [_createTextVNode("乘")]),
                  _: 1
                }), _createVNode(_component_el_radio_button, {
                  label: 4
                }, {
                  default: _withCtx(() => [_createTextVNode("除")]),
                  _: 1
                })]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "size"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 3,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"])])]);
    };
  }
};